import React, { useState, useRef, useEffect } from 'react';
import styled, {css} from 'styled-components';
import { VscChevronRight, VscChevronLeft } from 'react-icons/vsc';
import { device } from '../../../config/theme';

interface ISliderProps {
    items: Array<React.ReactNode>,
    width: string,
    itemCount: number,
    betweenItems: number,
}

interface ISlideProps{
    slideTranslate: number
}

interface ISliderState {
    activeIndex: number,
    translate: number,
    itemWidth: number
}

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: ${(props: ISliderProps) => props.width};
    @media ${device.mobileXS} { 
            padding: 0 10px;
    } 
    @media ${device.tablet} { 
        padding: 0 30px; 
    }
`

const ImageWindow = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  flex: 1;
`;

const Slide = styled.div<ISlideProps>`
  transform: translateX(${(slideProps: ISlideProps) => slideProps.slideTranslate}px);
  display: flex;
  align-items: center;
  height: 100%;
  transition: transform 0.3s ease-in-out;
`;

interface IArrow{
    disabled: boolean
}
const Arrow = styled.div<IArrow>`
    border-radius: 50%;
    box-shadow: ${props => props.theme.boxShadow};
    background-color: #990000;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center; 
    cursor: pointer;
    
    ${(props: IArrow) => props.disabled && css`
        opacity: 0.5; 
        cursor: default;
    `}
`;

export const Slider: React.FC<ISliderProps> = (props: ISliderProps) => {
    const [state, setState] = useState<ISliderState>({
        activeIndex: 0,
        translate: 0,
        itemWidth: 0
    });
    const { translate, activeIndex, itemWidth } = state;
    const ref = useRef(null);
    
    const ref_eff = ref.current && ref.current.offsetWidth;
    useEffect(() => {
        setState({...state, itemWidth: (ref.current.offsetWidth  / props.itemCount) - props.betweenItems});
    // TODO not sure if there is smth to do here
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref_eff]);
    
    const nextSlide = () => {
        if (activeIndex === (props.items.length ) - props.itemCount) {
            return;
        }

        setState({
            ...state,
            activeIndex: activeIndex + 1,
            translate: -((activeIndex + 1) * itemWidth + (activeIndex + 1) * props.betweenItems)
        })
    }
    
    const prevSlide = () => {
        if (activeIndex === 0) {
            return;
        }

        setState({
            ...state,
            activeIndex: activeIndex - 1,
            translate: -((activeIndex - 1) * itemWidth + (activeIndex - 1) * props.betweenItems)
        })
       
    }

    return(
        
        <Wrapper {...props}>
            <Arrow 
                disabled={activeIndex === 0 ?  true: false} 
                onClick={prevSlide}
            >
                <VscChevronLeft color={'white'} size={50}/>
            </Arrow>
            <ImageWindow ref={ref}>
                <Slide slideTranslate={translate}>
                
                    {props.items.map((item, idx) => (
                        <Content contentWidth={itemWidth} key={idx} item={item} margin={(props.betweenItems/2)}/>
                    ))}
                    
                </Slide> 
            </ImageWindow>
            <Arrow 
                disabled={activeIndex === (props.items.length ) - props.itemCount ?  true: false}
                onClick={nextSlide}
            >
                <VscChevronRight color={'white'} size={50}/>
            </Arrow>
        </Wrapper>
    );
}


interface IContent {
    item: React.ReactNode,
    contentWidth: number,
    margin: number
}

interface IItemContent {
    width: number,
    margin: number
}
const ItemContent = styled.div<IItemContent>`
    width: ${(props: IItemContent) => props.width}px;
    margin: ${(props: IItemContent) => props.margin}px;
`;

const Content: React.FC<IContent> = (props: IContent) => {
   
    return(
        <ItemContent margin={props.margin} width={props.contentWidth}>
            {props.item}
        </ItemContent>
    );
}
