import React from 'react';
import styled from 'styled-components';
import {Card} from '../util/Card';
import {ScaledImg} from '../util/ScaledImg';
import {Button} from '../util/Button';
import {MdSend} from 'react-icons/md';
import {Link} from 'react-router-dom';
import { device } from '../../config/theme';

interface IProduct{
    image: string,
    name: string,
    width: string,
    margin: string,
    url: string
}

const ImageWrapper = styled.div<{width: string}>`
    & > img {
        width: ${(props) => props.width};
        border-radius: ${props => props.theme.borderRadius} ${props => props.theme.borderRadius} 0px 0px;
        display: block;
    }
`;

const Title = styled.div`
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 400;
`;

const Content = styled.div`
    padding: 0 10px;
    @media ${device.tablet} { 
        padding: 0 30px;
    }
    height: 80px;
    display: flex;
    align-items:center;
    justify-content: space-between;
`;

export const Product: React.FC<IProduct> = (props: IProduct) => {
   
    return(
        <Card width={props.width} margin={props.margin}> 
            <ImageWrapper width={props.width}>
                <ScaledImg src={props.image} alt={props.name} /> 
            </ImageWrapper>

            <Content>
                <Title>{props.name}</Title>
                    <Link to={`/${props.url}`}>
                        <Button icon={<MdSend color={'white'} size={20}/>} fontSize={'0.8rem'} iconSide={'right'}> 
                            Tovább
                        </Button>
                    </Link>
            </Content>
        </Card>
    );
}