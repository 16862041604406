import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "typeface-roboto";
import { createMuiTheme, ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import {ThemeProvider} from 'styled-components';
import * as theme from './config/theme';
import GlobalStyle from './config/global-styles';

const MUItheme = createMuiTheme({
  palette: {
    primary: { main: '#1D1B1B'},
    secondary: {main: '#990000' }
  }
});

ReactDOM.render(
  <MUIThemeProvider theme={MUItheme}>
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <React.StrictMode>
        <App />
    </React.StrictMode>
  </ThemeProvider>
  </MUIThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
