
export const primary = "#1D1B1B";
export const secondary= "#990000";
export const black = "#181818";
export const white = "#fbfbfb";

export const borderRadius = "5px";
export const boxShadow = "0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)";

export const nightRider= "#303030";
export const pursionRed = "#ce2e30";
export const whiteSmoke = "#f6f6f6";
//black tones #474747 #4d515c ##545454
//red tones #D65A4F #D7766C #D9200C
export const deviceSizes = {
    mobileXS: '0',
    mobileS: '320',
    mobileM: '375',
    mobileL: '425',
    tablet: '768',
    laptop: '1024',
    laptopL: '1440',
    desktop: '2560'
  }

  export const device = {
    mobileXS: `(min-width: ${deviceSizes.mobileXS}px)`,
    mobileS: `(min-width: ${deviceSizes.mobileS}px)`,
    mobileM: `(min-width: ${deviceSizes.mobileM}px)`,
    mobileL: `(min-width: ${deviceSizes.mobileL}px)`,
    tablet: `(min-width: ${deviceSizes.tablet}px)`,
    laptop: `(min-width: ${deviceSizes.laptop}px)`,
    laptopL: `(min-width: ${deviceSizes.laptopL}px)`,
    desktop: `(min-width: ${deviceSizes.desktop}px)`,
    desktopL: `(min-width: ${deviceSizes.desktop}px)`
  };