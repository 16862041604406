import React from 'react';

interface IImg {
    src: string,
    alt: string
};

export const ScaledImg: React.FC<IImg> = (props: IImg) => {
    const src = process.env.PUBLIC_URL + '/' + props.src;
    return(
        <img 
            srcSet={`
                ${src}?nf_resize=fit&w=320 320w,
                ${src}?nf_resize=fit&w=480 480w,
                ${src}?nf_resize=fit&w=800 800w`}
            sizes="(max-width: 320px) 320px,
                   (max-width: 1200px) 480px,
                   800px"
            alt={props.alt}
            src={src}
        />
    );
}