import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import { AiFillHome } from "react-icons/ai";
const Wrapper = styled.div`
    margin-top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;  
    flex-direction: column;  
    height: 600px;
    background-color: ${props=> props.theme.whiteSmoke};
`;

const Text = styled.div`
    color: ${props=> props.theme.secondary};
    font-weight: 200; 
    font-size: 10rem;
`;
const SubText = styled.div`
    font-size: 2rem;
`;
const HomeButton = styled.div`
    border: 1px solid ${props=> props.theme.secondary};
    border-radius: 4px;
    margin-top: 20px;
    padding: 5px;
    cursor: pointer;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const Page404 = () => {
    useEffect(() => {
        // https://developers.google.com/search/docs/guides/fix-search-javascript
        const metaRobots = document.createElement('meta');
        metaRobots.name = 'robots';
        metaRobots.content = 'noindex';
        document.head.appendChild(metaRobots);
        return () => {
            document.querySelector("[name='robots']").remove();
        }
    });

    return (
        <Wrapper>
            <Text>404</Text>
            <SubText>Az oldal nem található!</SubText>
            <Link to='/'>
            <HomeButton>
                <AiFillHome />
                <span style={{marginLeft: "10px"}}>Vissza a címlapra</span>
            </HomeButton>
            </Link>
        </Wrapper>
       
    );
}