import React from 'react';
import styled from 'styled-components';
import {Slider} from '../util/slider/Slider';
import {Reference} from './Reference';
import {useWindowDimensions} from '../../hooks/hooks';
import { device, deviceSizes } from '../../config/theme';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 180px;
    position: relative;
    background-color: ${props => props.theme.whiteSmoke};
    @media ${device.mobileXS} { 
        margin: 0;
    } 
    @media ${device.moblieL} { 
        margin: 20px 0 0 0;
    }
    @media ${device.moblieL} { 
        margin: 0;
    }
`;

 /*<Reference src={'./img/references/cocacola.png'} margin={'0px'} width={'inherit'}></Reference>,*/
const references: Array<React.ReactNode> = [
    
    <Reference src={process.env.PUBLIC_URL + '/img/references/dreher.png'} margin={'0px'} width={'inherit'}></Reference>,
    <Reference src={process.env.PUBLIC_URL + '/img/references/hardrock.png'} margin={'0px'} width={'inherit'}></Reference>,
    <Reference src={process.env.PUBLIC_URL + '/img/references/starbucks.png'} margin={'0px'} width={'inherit'}></Reference>,
    <Reference src={process.env.PUBLIC_URL + '/img/references/zsidai.png'} margin={'0px'} width={'inherit'}></Reference>,
    <Reference src={process.env.PUBLIC_URL + '/img/references/heineken.png'} margin={'0px'} width={'inherit'}></Reference>
]

export const References: React.FC = () => {
    const {width} = useWindowDimensions();
    
    return(
        
        <Wrapper>
            {width <= parseInt(deviceSizes.mobileL) ? 
            <Slider items={references} itemCount={2} width={'100%'} betweenItems={60}/>
            :
            <>
            {width <= parseInt(deviceSizes.tablet) ?
                <Slider items={references} itemCount={3} width={'100%'} betweenItems={100}/>
                :
                <>
                {width <= parseInt(deviceSizes.laptop) ?
                    
                    <Slider items={references} itemCount={4} width={'100%'} betweenItems={150}/>
                    :
                    <>
                    {width <= parseInt(deviceSizes.laptopL) ? 
                        <Slider items={references} itemCount={4} width={'100%'} betweenItems={200}/>
                        :
                        <>
                        {width <= 1700  ? 
                            <Slider items={references} itemCount={4} width={'100%'} betweenItems={200}/>
                            :
                            <>
                            {width >= 2000  ?
                                <Slider items={references} itemCount={4} width={'2000px'} betweenItems={340}/>
                                :
                                <Slider items={references} itemCount={4} width={'100%'} betweenItems={320}/>
                            }
                            </>
                        }
                        </>
                    }
                    </>
                    
                }
                </>
            }
            </>
            }   
            
        </Wrapper>
        
    );
}