import React from 'react';
import styled from 'styled-components';

interface IReference {
    src: string,
    width: string,
    margin: string
}

const Image = styled.img`
    display: block;
    width: ${(props: IReference) => props.width};
    margin-left: ${(props: IReference) => props.margin};
    margin-right: ${(props: IReference) => props.margin};
`;

export const Reference: React.FC<IReference> = (props: IReference) => {
   
    return(

       <Image {...props}/>
        
    );
}