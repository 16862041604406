import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import { MdAccessTime } from 'react-icons/md';
import { VscChevronRight, VscChevronLeft, VscDebugRestart } from 'react-icons/vsc';
import { Button } from '../util/Button';
import { ProgressBar } from '../util/ProgressBar';
import { MdSend } from 'react-icons/md';
import styled, {css} from 'styled-components';
import { useForm } from 'react-hook-form';
import { device } from '../../config/theme';
// https://dev.to/deboragaleano/how-to-create-a-netlify-contact-form-in-react-5bma


const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 260px;
  flex-direction: column;

  @media ${device.tablet} { 
        height: 340px;
    } 
`;

const Time = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(61, 61, 61);
    font-size: 0.8rem;
`;

const StartButton = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
`;

const Steppnumber = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
`;

interface IDirectionsButton{
    isSend: boolean
}
const DirectionsButton = styled.div<IDirectionsButton>`
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    width: 50px;
    height: 25px;
    display: flex;
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    right: 0px;
    color: white;
    ${(props: IDirectionsButton) => props.isSend && css`
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        width: 90px;
    `}
`;

interface IDirectionsButtonSegmant{
    opacity: number
}
const DirectionsButtonSegmant = styled.div<IDirectionsButtonSegmant>`
    flex: 1; 
    align-items: center;
    justify-content: center;
    display: flex; 
    background-color: ${(props) => props.theme.secondary};
    opacity: ${(props: IDirectionsButtonSegmant) => props.opacity};
`;
const Divider = styled.div`
   height: 100%;
   width: 1px;
   opacity: 0.5;
   background-color: ${(props) => props.theme.secondary};
`;

const numberOfSteps = 5;

export const Contact = () => {
    const [success, setSuccess] = useState(false);
    const [activeStep, setActiveStep] = React.useState(0);

    const handleSetActiveStep = (value: number): void => {
        setActiveStep(value);
    };

    const handleStart = () => {
        setActiveStep(1);
    };

    const handleNext = () => {
        if(activeStep < numberOfSteps){
            setActiveStep((prevActiveStep) => prevActiveStep + 1);   
        }     
    };
    
    const handleBack = () => {
        if(activeStep > 1){
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    useEffect(() => {
      if ( window.location.search.includes('success=true') ) {
        setSuccess(true);
      }
    }, []);

    return (
        <div style={{height: "100%", position: "relative"}}>
            <ProgressBar steps={activeStep <= numberOfSteps ? activeStep : numberOfSteps} numberOfSteps={numberOfSteps} />
            <Steppnumber>
                {activeStep > 0 && activeStep <= numberOfSteps ? <StepsNumber>{activeStep}/{numberOfSteps}</StepsNumber> : null}
            </Steppnumber>
            <Wrapper>
            
                <Steps activeStep={activeStep} handleSetActiveStep={handleSetActiveStep}/>
              
                {activeStep === 0 ?
                <>
                <StartButton onClick={handleStart}>
                    <Button fontSize={"1rem"}>Kérjen ajánlatot!</Button>
                </StartButton>
                <Time>
                    <MdAccessTime size={18}/>
                    <span style={{marginLeft: "5px"}}>Csak 1 perc</span> 
                </Time>
                </>
                : 
                <>
                {activeStep <= numberOfSteps ?
                <DirectionsButton isSend={activeStep === numberOfSteps + 2}>    
                    <>
                    <DirectionsButtonSegmant opacity={activeStep === 1 ? 0.5 : 1} onClick={handleBack} style={activeStep === 1 ? {borderRadius: "4px 0 0 4px", cursor: "default"} : {borderRadius: "4px 0 0 4px"}}>
                        <VscChevronLeft color={'white'} size={20}/>
                    </DirectionsButtonSegmant> 
                        <Divider />
                    <DirectionsButtonSegmant opacity={activeStep === numberOfSteps ? 0.5 : 1} onClick={handleNext} style={activeStep === numberOfSteps ? {borderRadius: "0 4px 4px 0", cursor: "default"} : {borderRadius: "0 4px 4px 0"}}>
                        <VscChevronRight color={'white'} size={20}/>
                    </DirectionsButtonSegmant>
                    </>
                </DirectionsButton> 
                : null
                }
                </>
                }

            </Wrapper>

            {success && (
                <p style={{ color: "green" }}>Thanks for your message! </p>
            )}
        </div>
    );
}
  

const StepsWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
`;
const StepsNumber = styled.div`
    color: ${(props) => props.theme.secondary};
    font-size: 0.8rem;
    font-weight: 800;
`;
const Text = styled.div`
    text-align: center;
    line-height: 1.75;
    font-size: 0.8rem;
    @media ${device.tablet} { 
        font-size: 1.2rem;
    } 
    padding: 0 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}


const Steps = (props) => {
    const { register, handleSubmit, reset, getValues , formState: { errors, dirtyFields } } = useForm({
        mode: "onSubmit" // TODO handle default values
    });

    const onSubmit = (values: any): void =>  {
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...values })
        })
        //.then(() => alert(JSON.stringify(values)))
        .then(() => props.handleSetActiveStep(numberOfSteps + 1))
        .catch(error => console.error(error)); // TODO handle error
    }

    const onError = () => {
        console.log(dirtyFields)
        if(errors.name){
            props.handleSetActiveStep(1);
        } else if (errors.email) {
            props.handleSetActiveStep(2);
        } else if (errors.area) {
            props.handleSetActiveStep(3);
        } else if (errors.location) {
            props.handleSetActiveStep(4);
        } else if (errors.message) {
            props.handleSetActiveStep(5);
        }      
    }
    
    const handleRetake = () =>  {
        reset();
        props.handleSetActiveStep(0);     
    }
    
    return(
        <form 
            name="contact" 
            method="POST" 
            action="/contact/?success=true"
            data-netlify="true" 
            onSubmit={handleSubmit(onSubmit, onError)}
            style={{width: "100%"}}
        >     
        
        <Text style={props.activeStep === 0 ? {display: "flex"} : {display: "none"}}>
            Üdvözöljük! Sikerült választania kínálatunkból?
        </Text>
        
       
        <StepsWrapper style={props.activeStep === 1 ? {display: "flex"} : {display: "none"}}>
            <Text>
                <div>Adja meg a nevét</div>
            </Text>
            <TextField 
                {...register("name", { 
                    required: {
                        value: true,
                        message: "Nem lehet üres."
                    },
                    /*pattern: {
                        value: /^[a-z\u00C0-\u02AB'´`]+\.?\s([a-z\u00C0-\u02AB'´`]+\.?\s?)+$/,
                        message: "must be a valiid name and lastname"
                    }*/ // TODO correct pattern
                })} 
                error={errors.name}
                helperText={errors.name && errors.name.message}
                variant="outlined" 
                color={"primary"} 
                label="név" 
                name="name" 
            />
        </StepsWrapper>
       
        <StepsWrapper style={props.activeStep === 2 ? {display: "flex"} : {display: "none"}}>
            <Text>
                <div style={{marginLeft: "5px"}}>Adja meg az elérhetőség</div>
            </Text>
            
            <TextField 
                color={"primary"} 
                type="email"
                {...register("email", { 
                    required: {
                        value: (getValues("phone") !== "" && !errors.phone) ? false : true,
                        message: "Nem lehet üres."
                    },
                    pattern: {
                        value: /\S+@\S+.\S+/,
                        message: "Ez nem email!"
                    }
                })} 
                error={errors.email}
                helperText={errors.email && errors.email.message}
                variant="outlined" 
                label="email" 
                name="email" 
            />
            <div style={{height: "10px"}}></div>
            <TextField 
                color={"primary"} 
                type="phone"
                {...register("phone", { 
                    required: {
                        value: (getValues("email") !== "" && !errors.email) ? false : true,
                        message: "Nem lehet üres."
                    }
                })} 
                error={errors.phone}
                helperText={errors.phone && errors.phone.message}
                variant="outlined" 
                label="telefonszám" 
                name="phone" 
            />
        </StepsWrapper>
        
        <StepsWrapper style={props.activeStep === 3 ? {display: "flex"} : {display: "none"}}>
            <Text>
                <div>Körülbelül hány m² területet szeretne lefedni?</div>
            </Text>
            <TextField 
                {...register("area", { 
                    required: {
                        value: true,
                        message: "Nem lehet üres."
                    },
                    min: {
                        value: 0,
                        message: "Nem lehet kevesebb mint 0."
                    },
                    max: {
                        value: 99999,
                        message: "Nem lehet több mint 99999." 
                    }
                
                })} 
                error={errors.area}
                helperText={errors.area && errors.area.message}
                variant="outlined" 
                type="number"
                color={"primary"} 
                label="méret" 
                name="area" 
            />
        </StepsWrapper>

        <StepsWrapper style={props.activeStep === 4 ? {display: "flex"} : {display: "none"}}>
            <Text>
                <div>Hová szeretné telepíteni termékenket?</div>
            </Text>
            <TextField 
                {...register("location", { 
                    required: {
                        value: true,
                        message: "Nem lehet üres."
                    }
                })} 
                error={errors.location}
                helperText={(errors.location && errors.location.message) || "például: Budapest 12 vagy Sopron"}
                variant="outlined" 
                color={"primary"} 
                label="telepítési helyszín" 
                name="location" 
            />
        </StepsWrapper>
        
        <StepsWrapper style={props.activeStep === 5 ? {display: "flex"} : {display: "none"}}>
                <Text>
                    <div style={{marginLeft: "5px"}}>Írja le nagyjából az ötletét</div>
                </Text>
                
                <TextField
                    {...register("message", { 
                        maxLength: {
                            value: 1000,
                            message: "Nem lehet több mint 1000 karakter."
                        }
                    })} 
                    error={errors.message}
                    helperText={(errors.message && errors.message.message) || "opcionális"}
                    variant="outlined"
                    color={"primary"}
                    multiline
                    label="üzenet"
                    name="message"
                    rows={4}    
                    fullWidth
                />

                <button type="submit" style={{marginTop: "10px"}}>  
                    <Button fontSize={"1rem"} icon={<MdSend color={'white'} size={20}/>} iconSide={'right'}>
                        Küldés
                    </Button>
                </button>                
            </StepsWrapper>
           
            <StepsWrapper style={props.activeStep === numberOfSteps + 1 ? {display: "flex"} : {display: "none"}}>
                <Text>
                    Köszönjük! Álltálaban két munkanapon belül reagálunk.
                </Text>
                <div onClick={handleRetake}>
                <Button fontSize={"1rem"} icon={<VscDebugRestart color={'white'} size={20}/>} iconSide={'left'}>
                    Ismétlés
                </Button>
                </div>
            </StepsWrapper>
        </form>
    );
}
  