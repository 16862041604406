import React from 'react';
import styled from 'styled-components';
import { device } from '../../config/theme';
import { ScaledImg } from './ScaledImg';

interface IImageTitle {
    src: string,
    title?: string,
    isHome?: boolean
}

const LandingWrapper = styled.div`
    @media ${device.mobileXS} { 
        flex-direction: column;
    }
    @media ${device.tablet} { 
        flex-direction: row;
    }  
    margin-top: 120px;
    display: flex;
    width: 100%;
    z-index: -1;
    position: relative;
`;

const ImageWrapper = styled.div`
    & > img {
        width: 100%;
        display: block;
    }
    @media ${device.mobileXS} { 
        width: 100%;
    }
    @media ${device.tablet} { 
        width: 50%;
    } 
`;

const SecondaryContainer = styled.div`
    position: relative;
    @media ${device.mobileXS} { 
        width: 100%;
        height: 150px;
    }
    @media ${device.mobileS} { 
        width: 100%;
        height: 200px;
    }
    @media ${device.mobileM} { 
        width: 100%;
        height: 250px;
    }
    @media ${device.mobileL} { 
        width: 100%;
        height: 280px;
    }
    @media ${device.tablet} { 
        width: 50%;
        height: inherit;
    } 
    background-color:  ${props => props.theme.secondary};
`;

const PrimaryContainer = styled.div`
    @media ${device.mobileXS} { 
        height: 150px;
        bottom: 10px;
        right: 10px;
        left: 10px;
    }
    @media ${device.mobileS} { 
        height: 200px;
        bottom: 10px;
        right: 10px;
        left: 10px;
    }
    @media ${device.mobileM} { 
        height: 250px;
        bottom: 10px;
        right: 10px;
        left: 10px;
    }
    @media ${device.mobileL} { 
        height: 280px;
        bottom: 10px;
        right: 10px;
        left: 10px;
    }
    @media ${device.tablet} { 
        width: 50%;
        height: inherit;
        top: 30px;
        bottom: 30px;
        right: 30px;
        left: calc(50% - 30px);
    } 
    background-color:  ${props => props.theme.primary};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: ${props => props.theme.borderRadius};
    z-index: 1;
    position: absolute; 
   
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

`;

const Text = styled.div`
    @media ${device.mobileXS} { 
        font-size: 1rem;
    }
    @media ${device.mobileM} { 
        font-size: 1.8rem;
    }
    @media ${device.tablet} { 
        font-size: 2.2rem;
    }
    @media ${device.laptop} { 
        font-size: 1.9rem;
    }
    @media ${device.laptopL} { 
        font-size: 2.5rem;
    }
    font-weight: 200;
    color: white;
    text-align: center;
`;

const SubText = styled.div`
     
    
    color: white;
    font-weight: 200;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100%;
    @media ${device.mobileXS} { 
        font-size: 0rem;
    }
    @media ${device.mobileM} { 
        font-size: 0.8rem;
    }
    @media ${device.mobileL} { 
        font-size: 01rem;
    }
`;

const Title = styled.div`
    font-size: 1.5rem;
    color: white;
    font-weight: 200;
`;

export const ImageTitle: React.FC<IImageTitle> = (props: IImageTitle) => {

    return(
        <LandingWrapper>
            <ImageWrapper>
                <ScaledImg src={props.src} alt="vendrey-title-photo" />
            </ImageWrapper>
            <SecondaryContainer />
            <PrimaryContainer>
                {props.isHome ? 
                    <>
                    <Text>Holnap is Nap lesz...</Text>
                    <SubText>Egyedi és kiváló minőségű napellenzők készítése 1985 óta</SubText> 
                    </>
                :
                    <Title>{props.title}</Title>
                }
            </PrimaryContainer> 
        </LandingWrapper>
    )
}
