import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {RouteComponentProps} from 'react-router';
import { useHistory } from 'react-router-dom';

import {ImageTitle} from '../components/util/ImageTitle';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';

import {getProductByUrl} from '../mockApi/ProductService';
import {Grid} from '../components/util/Grid';
import {device, deviceSizes} from '../config/theme';
import {useWindowDimensions} from '../hooks/hooks';

import { ScaledImg } from '../components/util/ScaledImg';
import {ICategory} from '../interfaces/IProduct';

interface Props {
  product: string,
  category: string 
}

const Wrapper = styled.div`
  background-color:  ${props => props.theme.whiteSmoke};
  width: 100%;
  /* @media ${device.mobileXS} { 
    padding: 0 10px;
  
  }
  @media ${device.tablet} { 
    padding: 0 30px;
  }   */
  /* .MuiTabs-root{
    width: 100%;
  } */
`;
const useStyles = makeStyles({
  root: {width: "100%" }, 
  flexContainer: {alignItems: "center", justifyContent: "center"}
});

export const ProductPage: React.FC<RouteComponentProps<Props>> = (props: RouteComponentProps<Props>) => {
  const history = useHistory();
  const {width} = useWindowDimensions();
  const name = props.match.params.product;
  const classes = useStyles();
  const product = getProductByUrl(name);
  if(product === undefined){
    history.replace('/404');
  }

  const category = props.match.params.category;
  const [value, setValue] = useState(0);

  useEffect(() => {
    const idx = product && product.categories.map(cat => cat.url).findIndex(cat => cat === category);
    setValue(idx === -1 ? 0 : idx);
  }, [history, product, category]);
 
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    history.push(`/${name}/${product.categories[newValue].url}`);
  };

  return (
    <>
      <ImageTitle src={`img/products/${name}.jpg`} title={product && product.title} />

      <Wrapper>
        
        {product && product.categories.length === 1 ?
        null :
        <>
          <Tabs
            TabIndicatorProps={{
              style: { background: "#990000"}
            }}
            classes={classes}
            value={value}
            onChange={handleChange}
            variant={width <= parseInt(deviceSizes.tablet) ? 'fullWidth' : 'standard'}
            orientation={width <= parseInt(deviceSizes.tablet) ? 'vertical' : 'horizontal'}
          >
            {
              product && product.categories.map((category, key: number) => {
                return (<Tab key={key} label={category.title} />);
              })}
          </Tabs>
        </>
      }
      </Wrapper>

      {
        product && product.categories.map((category, key: number) => {
          return (
            <TabPanel category={category} value={value} index={key} key={key}>
              Item {key}
            </TabPanel>
          );
        })
      }
    </>
  );
}

interface ITabPanel {
  children: React.ReactNode,
  index: any,
  value: any,
  category: ICategory,
}


const TabsWrapper = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.div`
  line-height: 1.75;
  text-align: center;
  font-size: 1.1rem;
  padding: 80px 10px;
  @media ${device.tablet} { 
    width: 100%;
    padding: 80px 30px;
  } 
  @media ${device.laptop} { 
    width: calc(1024px - 60px);
  }
`;

const ImageWrapper = styled.div`
  & > img {
    width: 100%;
    border-radius: ${props => props.theme.borderRadius};
    box-shadow: ${props => props.theme.boxShadow};
  }
`;

const Background = styled.div`
  width: 100%;
  background-color:  ${props => props.theme.whiteSmoke};
`;

const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Table = styled.div`
  padding: 0px 0px 30px;
   display: flex;
   flex-direction: column;
   align-items: center;
   div:last-child{
    border-bottom: 2px solid white;
   }
`;
const TableRow = styled.div`
   border-bottom: 2px solid ${props => props.theme.whiteSmoke};
   display: flex;
   justify-content: flex-start;
   width: 100%;
   padding: 10px;
`;
const RowSymbol = styled.div`
   
`;
const RowTitle = styled.div`
   font-weight: 500;
   padding-right: 50px;
   
`;
const RowDetails = styled.div`
  display: inline-grid;
 
  color: #787878;
  @media ${device.mobileXS} { 
        align-self: center;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
      
  } 
  @media ${device.mobileL} { 
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media ${device.tablet} { 
    grid-gap: 30px;
  }
  @media ${device.laptop} { 
    grid-auto-flow: column;
  }
`;

const TableTitle = styled.div`
    font-weight: 200;
    @media ${device.mobileXS} { 
        font-size: 1rem;
    } 
    @media ${device.moblieL} { 
        font-size: 2rem;
    }
    @media ${device.moblieL} { 
        font-size: 2rem;
    }
    text-align: center;
    color:  ${props => props.theme.primary};
    margin-bottom: 20px;
    margin-top: 20px;
`;
const Line = styled.div`
   background-color:  ${props => props.theme.secondary};
   width: 90px;
   height: 2px;
   margin-bottom: 25px;
`;
const TabPanel: React.FC<ITabPanel> = (props: ITabPanel) => {
  
    return (
      <TabsWrapper>
        {props.value === props.index && (
          <>
          <Text>
            <div dangerouslySetInnerHTML={ {__html: props.category.description} } />
          </Text>

          {props.category.details.length > 0 ? 
          <>
          <TableTitle>
            Méretek
          </TableTitle>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Line />
          </div>
          
          <Table>
          {props.category.details.map((detail, key) => (
              <TableRow key={key}>
                  <RowSymbol></RowSymbol>
                  <RowTitle>{detail.title}</RowTitle>
                  <RowDetails> 
                    {detail.sizes.map((size, key) => (
                      
                      <div style={{margin: "0px"}}>{size}</div>
                      
                    ))}
                  </RowDetails>              
            </TableRow>
          ))}
          </Table>
          </>
          :
          null
          }
          
          <Background>
          <GridWrapper>
            <Grid>
              {props.category.pictures.map((picture, key) => (
                <ImageWrapper key={key}>
                  <ScaledImg src={picture} alt={`${props.category.title.replaceAll(' ', '-')}-${key+1}`}/>
                </ImageWrapper>
              ))}
            </Grid>
          </GridWrapper>
          </Background>
          </>
        )}
      </TabsWrapper>
    );
  }
