import React , {useState, useEffect} from 'react';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import styled from 'styled-components';
import {Navigation} from './components/navigation/Navigation';
import {Home} from './pages/Home';
import {ProductPage} from './pages/ProductPage';
import {AboutUs, Imprint} from './pages/AboutUs';
import {RALColors} from './pages/RALColors';
import {Footer} from './components/footer/Footer';
import {Page404} from './components/error/Page404';
import ScrollToTop from './components/util/ScrollToTop';

const FixedWrapper = styled.div`
  position:fixed;
  top: 0px;
  width: 100%;
  z-index: 2;
`;

const forwardUrl = (url) => { 
  window.location.replace(url); 
  return null;
}

function App() {
  const [isLoading, setLoading] = useState(true);

  function timeOut() {
    return new Promise((resolve, reject) => {
      let wait = setTimeout(() => {
        clearTimeout(wait);
        resolve('');
      }, 2000)
    })
  }

  useEffect(() => {
    timeOut().then(() => {
      const el = document.querySelector(".loader-container-wrapper");
      if (el) {
        el.remove();
        setLoading(!isLoading);
      }
    });
  });

  if (isLoading) {
    return null;
  }

  return (
    
    <Router >
      <ScrollToTop />
      <FixedWrapper>
          <Navigation />
      </FixedWrapper>
     
      <Switch>
        <Route path="/404" component={Page404} exact/>
        <Route path="/" component={Home} exact/>
       
        <Route path="/rolunk" component={AboutUs} exact/>
        <Route path="/imprint" component={Imprint} exact/>
        <Route path="/tos" component={() => forwardUrl("https://drive.google.com/")}/>
        <Route path="/ral" component={RALColors}/>
        <Route path="/sattler" component={() =>
          forwardUrl("https://suntex.sattler.com/design-selector/design-selector-sonnenschutzstoffe")
        }/>
        <Route path="/:product/:category?" component={ProductPage} exact/>
        <Route component={Page404} />
      </Switch>
      
      <Footer /> 
    </Router>
  );
}

export default App;
