import React, { useState, useEffect } from "react";
import styled from 'styled-components';

const Container = styled.div`
  height: 4px;
  width: 90%;
  position: relative;
  margin: 0 auto;
`;

const BaseBox = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: width 200ms ease-in-out;
`;

const Background = styled(BaseBox)`
    background: ${(props) => props.theme.whiteSmoke};
    width: 100%;
`;

const Progress = styled(BaseBox)<IBaseBox>`
  background: ${(props) => props.theme.secondary};
  
  width: ${(props: IBaseBox) => props.percent}%;
`;

interface IBaseBox {
    percent: number
}

interface IProgressBar {
    steps: number,
    numberOfSteps: number
}
export const ProgressBar: React.FC<IProgressBar> = (props: IProgressBar) => {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    setPercent(props.steps * (100 / props.numberOfSteps));
  }, [props.steps, props.numberOfSteps]);

  return (
    <Container>
        <Background />
        <Progress percent={percent} />
    </Container>
  );
}
