import React, {useState, useEffect} from 'react';
import {Product} from './Product';
import {deviceSizes} from '../../config/theme';
import products from '../../data/products.json';
import {useWindowDimensions} from '../../hooks/hooks';
import {Grid} from '../util/Grid';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const ProductGrid: React.FC = () => {

    const {width} = useWindowDimensions();
    const [productWidth, setProductWidth] = useState('0px');
    const [productMargin, setproductMargin] = useState('0px');

    useEffect(() => {
        if(width >= parseInt(deviceSizes.mobileXS)){
            setProductWidth('100%');
            setproductMargin('0px');
        } 
        if(width >= parseInt(deviceSizes.tablet)){
            setProductWidth('100%');
            setproductMargin('0px');
        } 
        if(width >= parseInt(deviceSizes.laptop)){

        } 
        if(width >= parseInt(deviceSizes.laptopL)){

        } 
        if(width >= parseInt(deviceSizes.desktop)){

        }

    }, [width]);

    return(
        <Wrapper>
        <Grid>
            {products.map((product, key) => {
                return(
                    <Product 
                        key={key}
                        image={product.src} 
                        name={product.title} 
                        width={productWidth} 
                        margin={productMargin}
                        url={product.url}
                    >

                    </Product>
                )
            })}
        </Grid> 
        </Wrapper>
    );
}