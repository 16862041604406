import React from 'react';
import styled from 'styled-components';
import {device} from '../../config/theme';

interface IGrid{
    children: React.ReactNode
}
const Wrapper = styled.div`
    display: grid;
    margin: 10px;
    @media ${device.mobileXS} { 
        grid-template-columns: 1fr;
        grid-gap: 10px;
    } 
    @media ${device.tablet} { 
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
        margin: 30px  10%;
    } 
    @media ${device.laptop} { 
        grid-template-columns: 1fr 1fr 1fr;
    }
    justify-content: space-around;
`;

export const Grid: React.FC<IGrid> = (props: IGrid) => {
    return(
        <Wrapper>
            {props.children}
        </Wrapper>   
    );
}