import React from 'react';
import styled from 'styled-components';
import { device } from '../config/theme';

const Wrapper = styled.div`
    padding-top: 30px;
    margin-top: 120px;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const WhatWeDoText = styled.div`
    font-weight: 200;
    @media ${device.mobileXS} { 
        font-size: 1rem;
    } 
    @media ${device.moblieL} { 
        font-size: 2rem;
    }
    @media ${device.moblieL} { 
        font-size: 2rem;
    }
    text-align: center;
    color:  ${props => props.theme.primary};
    margin-bottom: 20px;
`;

const Text = styled.div`
    line-height: 1.75;
    text-align: center;
    font-size: 1.1rem;
    padding: 30px 10px;
    @media ${device.tablet} { 
        width: 100%;
        padding: 30px 30px;
    } 
    @media ${device.laptop} { 
        width: calc(1024px - 60px);
    }
`;

const Line = styled.div`
   background-color:  ${props => props.theme.secondary};
   width: 90px;
   height: 2px;
   margin-bottom: 25px;
`;

const Picture = styled.img`
    margin: 10px;
    width: calc(100% - 20px);
    @media ${device.tablet} { 
        width: 300px;
    } 
    border-radius: ${props => props.theme.borderRadius};
    box-shadow: ${props => props.theme.boxShadow};
`;

const Background = styled.div`
    position: relative;
    width: 100%;
    display:  flex;
    justify-content: center;
    background-color:  ${props => props.theme.whiteSmoke};
    @media ${device.tablet} { 
        padding: 30px;
    } 
`;


export const AboutUs: React.FC = () => {
    return(
        <Wrapper> 
            <WhatWeDoText>
                Rólunk
            </WhatWeDoText>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Line />
            </div>
            <Text>
                Cégünk 1985-ben kezdte meg tevékenységét az árnyékolás technológia területén.
                Közben folyamatosan bővíti tevékenységi körét, jelenlegi telephelyén ahova 1994-ben
                költöztünk. 2006 óta megkaptuk az ISO 9001 minősítést. Egyike vagyunk azon kevés
                cégeknek akik saját fejlesztésű árnyékolástechnikai eszközöket is gyárt. Fő
                tevékenységünk a napernyők illetve a falra vagy mennyezetre szerelt napellenzők, de
                egyedi igényeket is ki tudunk elégíteni. Cégünk nagy tapasztalatra tett szert a
                könyökkaros és annak továbbfejlesztéseként megszülető Herkules karos napellenzők
                terén. Kínálatunkban szerepel a méreteiről méltán híres ollókaros és felső lefutósínes
                napellenzők is.
            </Text>

            <Background>
                <Picture src={'./img/rolunk/rolunk1.jpg'} alt="rolunk" />
            </Background>

        </Wrapper>
    );
}

export const Imprint: React.FC = () => {
    return(
        <Wrapper> 
            <WhatWeDoText>
                Impresszum
            </WhatWeDoText>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Line />
            </div>
            <Text>
                <u>A honlap üzemeltetője:</u><br />
                Cégnév: Vendrey és Társa Kft<br />
                Székhely: 1125 Budapest, Béla király út 27A<br />
                Telephely: 1036 Budapest, Fényes Adolf u. 19-21<br />
                Adószám: 12304473-2-43<br />
                Cégjegyzékszám: 01-09-664773<br />
                Képviseli: Vendrey Dénes<br />
            </Text>

        </Wrapper>
    );
}