import React from 'react';
import styled from 'styled-components';
import {device} from '../config/theme';

import {Card} from '../components/util/Card';

const colors = [
  {name: "Oyster white", hex: "#E9E5CE", code: 1013},
  {name: "Light ivory", hex: "#EADEBD", code: 1015},
  {name: "Anthracite grey", hex: "#373F43", code: 7016},
  {name: "Black grey", hex: "#2E3234", code: 7021},
  {name: "Umbra grey", hex: "#4B4D46", code: 7022},
  {name: "Traffic grey A", hex: "#8F9695", code: 7042},
  {name: "Chocolate brown", hex: "#44322D", code: 8017},
  {name: "Signal white", hex: "#F4F4F4", code: 9003},
  {name: "Jet black", hex: "#0A0A0A", code: 9005},
  {name: "White aluminium", hex: "#A5A5A5", code: 9006},
  {name: "Pure white", hex: "#FFFFFF", code: 9010}
]

const Wrapper = styled.div`
  margin-top: 150px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  padding: 10px;

  @media ${device.mobileL} { 
    grid-gap: 30;
    padding: 30px;
    grid-template-columns: 1fr 1fr;
  }
  @media ${device.tablet} { 
    grid-gap: 30;
    padding: 30px;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media ${device.laptop} { 
    grid-gap: 30;
    padding: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 800px;
    margin: 0 auto;
  }
`;

const ColorWrapper = styled.div`

`;

interface IColor {
  color: string
}
const CardInner = styled.div<IColor>`
  background-color: ${props => props.color};
  height: 100px;
  border-radius: 4px;
`;

const CardTitle = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px;
`;

const Title = styled.div`
    font-weight: 200;
    @media ${device.mobileXS} { 
        font-size: 1rem;
    } 
    @media ${device.moblieL} { 
        font-size: 2rem;
    }
    @media ${device.moblieL} { 
        font-size: 2rem;
    }
    text-align: center;
    color:  ${props => props.theme.primary};
    margin-bottom: 20px;
`;
const Line = styled.div`
   background-color:  ${props => props.theme.secondary};
   width: 90px;
   height: 2px;
   margin-bottom: 25px;
`;

const Comment = styled.div`
  text-align: center;
  margin-bottom: 20px;
  a {
    text-decoration: underline;
  }
`;

export const RALColors: React.FC = () => {

  return (
    <Wrapper>
      <Title>
          RAL színek
      </Title>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Line />
      </div>
      <Grid>
          {colors.map(color => (
            <ColorWrapper>
              <Card>
                <CardInner color={color.hex}>
                </CardInner>
              </Card>
              <CardTitle>
                {color.code}<br />{color.name}
              </CardTitle>
            </ColorWrapper>
          ))}
      </Grid>
      <Comment>
        A színek pontos kinézete az Ön képernyő beállításaitól függ. <a 
          href="https://www.ral-farben.de/alle-ral-farben#classic" 
          target="_blank"
          rel="noreferrer"
        >
          További RAL CLASSIC színek a RAL oldalan találhatok.
        </a>
      </Comment>
    </Wrapper>
  );

}