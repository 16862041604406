import React from 'react';
import styled, {css} from 'styled-components';

interface ICard {
    width?: string,
    margin?: string,
    height?: string,
    padding?: string,
    children: React.ReactNode,
}

const Wrapper = styled.div<ICard>`
    ${(props: ICard) => props.width && props.margin  && css`
        width: calc(${(props: ICard) => props.width} - 2 * ${(props: ICard) => props.margin});
    `}
    ${(props: ICard) => props.width && !props.margin && css`
        width: ${(props: ICard) => props.width};
    `}
    ${(props: ICard) => props.margin && css`
        margin: ${(props: ICard) => props.margin};
    `}
    ${(props: ICard) => props.height && css`
         height: ${(props: ICard) => props.height};
    `}
    ${(props: ICard) => props.padding && css`
         padding: ${(props: ICard) => props.padding};
    `}
    background-color: white;
    border-radius: ${props => props.theme.borderRadius};
    box-shadow: ${props => props.theme.boxShadow};
`;

export const Card: React.FC<ICard> = (props: ICard) => {
    return(
        <Wrapper {...props}>
            {props.children}
        </Wrapper>
    )
}
