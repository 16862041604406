import React from 'react';

import styled, {css} from 'styled-components';

interface IButton {
    fontSize: string,
    children: React.ReactNode,
    icon?: React.ReactNode,
    iconSide?: string,
    color?: string,
    width?: string
}

const Wrapper = styled.div<IButton>`

    background-color: ${props => props.theme.secondary}; 
    ${(props: IButton) => props.color && css`
        background-color: ${(props: IButton) => props.color};
    `}
    border-radius: ${props => props.theme.borderRadius};
    box-shadow: ${props => props.theme.boxShadow};
    
    color: white; 
    ${(props: IButton) => props.color && css`
        color: black;
    `}
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    border: none;
    padding: 0 16px;
    min-width: 64px;
    ${(props: IButton) => props.width && css`
        width: ${(props: IButton) => props.width};
    `}
    height: 36px;
    vertical-align: middle;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: uppercase;
    font-size: ${(props: IButton) => props.fontSize};
    font-weight: 500;
    line-height: 36px;
    overflow: hidden;
    outline: none;
    cursor: pointer;
    transition: box-shadow 0.2s;

    &::-moz-focus-inner {
        border: none;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
        opacity: 0;
        transition: opacity 0.2s;
    }

    &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 50%;
        padding: 50%;
        width: 32px; /* Safari */
        height: 32px; /* Safari */
        background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
        opacity: 0;
        transform: translate(-50%, -50%) scale(1);
        transition: opacity 1s, transform 0.5s;
    }

    &:hover,
    &:focus {
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    &:hover::before {
        opacity: 0.08;
    }

    &:focus::before {
        opacity: 0.24;
    }
    &:hover:focus::before {
        opacity: 0.3;
    }
    &:active {
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
    &:active::after {
        opacity: 0.32;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0s;
    }
    &:disabled {
        color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
        background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.12);
        box-shadow: none;
        cursor: initial;
    }
    &:disabled::before {
        opacity: 0;
    }
    &:disabled::after {
        opacity: 0;
    }
`;

const InnerWrapper = styled.div`
    display: flex;
    align-items: center; 
    justify-content: center;
`;

export const Button: React.FC<IButton> = (props: IButton) => {
    
    return(
       
        <Wrapper {...props}>
            <InnerWrapper>
            {props.iconSide === 'left' ?
                <>
                {props.icon ? props.icon : null}
                {props.icon ? <div style={{width: "15px", height: "100%"}}/> : null}
                {props.children}
                </>
            :
                <>
                {props.children}
                {props.icon ? <div style={{width: "15px", height: "100%"}}/> : null}
                {props.icon ? props.icon : null}
                </>
            } 
            </InnerWrapper>
        </Wrapper>
        
        
    );
}