import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import {References} from '../components/references/References';
import {ProductGrid} from '../components/products/ProductGrid';
import {ImageTitle} from '../components/util/ImageTitle';
import { device } from '../config/theme';

const Content = styled.div`
    margin: 100px 0;
    width: 100%;
`;
const WhatWeDoText = styled.div`
    font-weight: 200;
    @media ${device.mobileXS} { 
        font-size: 1rem;
    } 
    @media ${device.moblieL} { 
        font-size: 2rem;
    }
    @media ${device.moblieL} { 
        font-size: 2rem;
    }
    text-align: center;
    color:  ${props => props.theme.primary};
    margin-bottom: 20px;
`;
const Line = styled.div`
   background-color:  ${props => props.theme.secondary};
   width: 90px;
   height: 2px;
   margin-bottom: 25px;
`;
export const Home: React.FC = () => {
    const history = useHistory();

    useEffect(() => {
        if(window.location.search !== '') {
            history.replace('/404');
        } 
    });
   
    return(
        <>
        <ImageTitle isHome src={'img/starting.jpg'}/> 
    
        <References /> 
            
        <Content>
            <WhatWeDoText>
                Termékeink
            </WhatWeDoText>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Line />
            </div>
            <ProductGrid />
        </Content> 

        </>
    );
}