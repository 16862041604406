import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import { MdMap, MdPhone, MdEmail } from "react-icons/md";
import { device } from '../../config/theme';

const UpperContainer = styled.div`
    width: 100%;
    background-color: ${props => props.theme.black};
    display: flex;
    justify-content: center;
    color: white;
    @media ${device.mobileXS} { 
        flex-direction: column;
    } 
    @media ${device.moblieL} { 
        flex-direction: column;
    }
    @media ${device.tablet} { 
        flex-direction: row;
    }
`;

const MiddleContainer = styled.div`
    width: 100%;
    background-color: ${props => props.theme.black};
    display: flex;
    justify-content: center;
    border: none;
    gap: 10px;
    align-items: center;
    font-size: 0.7rem;
    @media ${device.tablet} { 
        font-size: 1rem;
        gap: 30px;
    } 
`;
const Devider = styled.div`
   background-color: ${props => props.theme.whiteSmoke};
   height: 18px;
   width: 2px;
`;
const LowerContainer = styled.div`
    border-top: 1px solid ${props => props.theme.secondary};
    width: 100%;
    background-color: ${props => props.theme.black};
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
`;
const Content = styled.div`
    
    @media ${device.mobileXS} { 
        padding: 10px;
    } 
    @media ${device.moblieL} { 
        padding: 20px;
    }
    @media ${device.tablet} { 
        padding: 20px;
    }
    @media ${device.laptop} { 
        padding: 50px;
    }
`;

const Text = styled.div`
    font-size: 0.8em;
    margin: 10px 0px;
    color: white;
    @media ${device.tablet} { 
        font-size: 1em;
    }
`;

const IconContent = styled.div`
    @media ${device.mobileXS} { 
        padding: 10px 0;
    } 
    @media ${device.moblieL} { 
        padding: 20px 0;
    }
    @media ${device.tablet} { 
        padding: 20px 0;
    }
    @media ${device.laptop} { 
        padding: 50px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Icon = styled.div`
    border-radius: 50%;
    box-shadow: ${props => props.theme.boxShadow};
    background-color: white;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center; 
    margin: 0 10px;
    cursor: pointer;
`;

export const Footer: React.FC = () => {
    return(
        <>
            <UpperContainer>
                <Content>
                    <Text>
                        Nyitva tartás: Hétfő - Péntek 06:45 - 16:15
                    </Text>
                    <Text>
                        Budapest 1036, Fényes Adolf u. 19-21
                    </Text>
                    <Text>
                        Tel.: +36 1 388 7963
                    </Text>
                    <Text>
                        Email: vendrey@vendrey.hu
                    </Text>
                </Content>

                <IconContent>
                    <a href="tel:+36 1 388 7963">
                        <Icon>
                            <MdPhone color={"black"} size={20}/>
                        </Icon>
                    </a>
                    <a href="mailto:vendrey@vendrey.hu">
                        <Icon>
                            <MdEmail color={"black"} size={20}/>
                        </Icon>
                    </a>
                    <a target="_blank" rel="noreferrer" href ="https://www.google.com/maps/place/Budapest,+F%C3%A9nyes+Adolf+u.+19,+1036+Hungary/data=!4m2!3m1!1s0x4741d9593c4807df:0x78d4c3dda270c05d">
                        <Icon>
                            <MdMap color={"black"} size={20}/>
                        </Icon>
                    </a>
                </IconContent>
            </UpperContainer>

            <MiddleContainer>
                <Link to={'/ral'}>
                    <Text>
                        RAL színek
                    </Text>
                </Link>
                <Devider />
                <Link to={'/sattler'}>
                    <Text>
                        Ponyvaminták
                    </Text>
                </Link>
                {/* <Devider />
                <Link to={'/tos'}>
                    <Text>
                        ÁSZF
                    </Text>
                </Link> */}
                <Devider />
                <Link to={'/imprint'}>
                    <Text>
                        Impresszum
                    </Text>
                </Link>
                <Devider />
                <Link to={'/rolunk'}>
                    <Text>
                        Rólunk
                    </Text>
                </Link>
            </MiddleContainer>

            <LowerContainer>
                &copy; 2021 Vendrey és Társa Kft.
            </LowerContainer>
        </>
    );
}