import React, { useState } from 'react';
import styled from 'styled-components';
import {Button} from '../util/Button';
import {MdPhone, MdEmail, MdClose, MdPermPhoneMsg} from "react-icons/md";
import {GiReceiveMoney} from "react-icons/gi";
import {useWindowDimensions} from '../../hooks/hooks';
import { device, deviceSizes } from '../../config/theme';
import {Link} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { Contact } from '../form/Contact';


const Wrapper = styled.div`
   
    width: 100%;
    height: 80px;
    box-shadow: ${props => props.theme.boxShadow};
    z-index: 99;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @media (min-width: 2000px) { 
      width: 2000px;
    }
    @media ${device.mobileXS} { 
        padding: 0 10px;
    }  
    @media ${device.tablet} { 
        padding: 0 30px;
    } 
`;

const Logo = styled.div`
    img{
        width: 80px;
        @media ${device.tablet} { 
            width: 100px;
        }  
    }
`;

const HeadBar = styled.div`
    height: 40px;
    background-color: ${props => props.theme.secondary};
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 0 10px;
    @media (min-width: 2000px) { 
      width: 2000px;
    }
`;
const HeadBarText = styled.div`
    font-size: 0.7rem;
    text-align: center;
    
    @media ${device.mobileS} { 
        margin-right: 10px;
    }  
    @media ${device.tablet} { 
        font-size: 1rem;
    }  
`;


export const Navigation: React.FC = () => {
    const [open, setOpen] = useState(false);
    const {width} = useWindowDimensions();
    

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    return( 
        <>
        <HeadBar>
            <HeadBarText>
                Covid-19: Állami otthonfelújítási támogatás árnyékolókat is tartalmaz.
            </HeadBarText>
            <GiReceiveMoney size={width <= parseInt(deviceSizes.mobileS) ? 30 : 20} />
        </HeadBar>
        <Wrapper>
            <Link to='/'>
                <Logo>
                    <img src={process.env.PUBLIC_URL + '/img/logo.svg'} alt='vendrey-logo'/>    
                </Logo> 
            </Link>
            <div onClick={handleClickOpen}>
                <Button  
                    icon={width <= parseInt(deviceSizes.mobileS) ? null : <MdPermPhoneMsg color={"white"} size={20}/>} 
                    fontSize={width <= parseInt(deviceSizes.mobileS) ? '0.5rem' : '0.8rem'} 
                    iconSide={'left'} 
                > 
                    {width <= parseInt(deviceSizes.tablet) ? 
                    "kapcsolat"
                    :
                    "Vegye fel velünk a kapcsolatot"}
                </Button>
            </div>
        </Wrapper>
        <DialogMenu open={open} onClose={handleClose} />
        </>
    );
}


const useStyles = makeStyles({
    root: {
        borderRadius: "5px",
        boxShadow: "0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)"  
    },
    closeButton: {
        position: 'absolute',
        right: "0px",
        top: "0px"
    },
});

const DialogTitle = styled.div`
    font-weight: 200;
    font-size: 1.5rem;
    @media ${device.tablet} { 
        font-size: 2rem;
    }
    font-weight: 700;
    font-size: 1.3rem;
    text-align: center;
    color: ${(props) => props.theme.secondary};
    padding: 10px 10px 0 10px;
`;

const DialogButtonWrapper = styled.div`
    padding: 10px;
    @media ${device.tablet} { 
        padding: 30px;
    } 
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.secondary};
`;

const DialogButton = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px;
  cursor: pointer;
`;
const DialagButtonBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
const DialogButtonTitle = styled.div`
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 1.1rem;
    @media ${device.tablet} { 
        font-size: 1.3rem;
    } 
`;
const DialogButtonText = styled.div`
    font-size: 0.7rem;
    @media ${device.tablet} { 
        font-size: 1rem;
    } 
`;

const DialogMenu = (props) => {
    const {width} = useWindowDimensions();
    const { onClose, selectedValue, open } = props;
    const classes = useStyles();

    const handleClose = () => {
        onClose(selectedValue);
    };

    /*const handleListItemClick = (value) => {
        onClose(value);
    };*/

    return (
    <Dialog disableScrollLock fullWidth onClose={handleClose} classes={classes} open={open}>
       
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <MdClose />
        </IconButton> 
        <DialogTitle>Vegye fel velünk a kapcsolatot!</DialogTitle>
        
        <div style={{padding: "10px"}}>
            <Contact />
        </div>

        <DialogButtonWrapper>        
            <div style={{display: "flex", flexDirection: "column"}}>    

            <a style={{color: "white"}} href="tel:+36 1 388 7963"> 
            <DialogButton > 
                <MdPhone size={width <= parseInt(deviceSizes.tablet) ? "30" : "40"} color="white"/> 
                <DialagButtonBody>
                    <DialogButtonTitle >
                        hívjon 
                    </DialogButtonTitle >
                    <DialogButtonText>
                        +36 1 388 7963
                    </DialogButtonText>
                </DialagButtonBody>  
            </DialogButton>
            </a>

            <a style={{color: "white"}} href="mailto:vendrey@vendrey.hu">
            <DialogButton>
                <MdEmail size={width <= parseInt(deviceSizes.tablet) ? "30" : "40"} color="white"/>
                <DialagButtonBody>
                    <DialogButtonTitle >
                        írjon
                    </DialogButtonTitle >
                    <DialogButtonText>
                        vendrey@vendrey.hu
                    </DialogButtonText>
                </DialagButtonBody>
            </DialogButton>
            </a>

            </div>
        </DialogButtonWrapper>
    </Dialog>
    );
}